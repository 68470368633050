<template>
  <div v-if="workoutExercisesByGroup.length">
    <div class="row justify-content-center my-3">
      <div class="col">
        <div class="d-flex align-items-center justify-content-between">
          <IconButton
            @click="backToOverview"
            :icon="'th-list'"
            class="btn btn-primary"
          ></IconButton>

          <div class="d-flex flex-grow-1 align-items-center justify-content-center">
            <div
              v-for="(exerciseGroup, exerciseGroupIndex) in workoutExercisesByGroup"
              :key="`key-${exerciseGroupIndex}`"
              :class="exerciseGroupIndex === activeIndex ? 'is-active' : ''"
              class="dot"
            ></div>
          </div>

          <button
            @click="prevCard"
            :disabled="isFirstCard"
            class="btn"
          >
            <fa-icon :icon="['fas', 'chevron-left']"></fa-icon>
            Previous
          </button>
          <button
            @click="nextCard"
            :disabled="isLastCard"
            class="btn"
          >
            Next
            <fa-icon :icon="['fas', 'chevron-right']"></fa-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center my-3">
      <div class="col">
        <div
          v-touch:swipe.left="handleSwipe('next')"
          v-touch:swipe.right="handleSwipe('prev')"
          v-for="(exerciseGroup, exerciseGroupIndex) in workoutExercisesByGroup"
          :key="`key-${exerciseGroupIndex}`"
        >
          <transition :name="transitionName">
            <div v-if="exerciseGroupIndex === activeIndex">
              <WorkoutExerciseSliderCard
                v-for="(exercise, exerciseIndex) in exerciseGroup.exercises"
                :key="`key-${exerciseIndex}`"
                :section="exerciseGroup.section"
                :exercise="exercise"
                :exercises="workoutSession.exercises"
                :class="exerciseIndex > 0 ? 'mt-3' : ''"
              ></WorkoutExerciseSliderCard>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'WorkoutExerciseSlider',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    WorkoutExerciseSliderCard: () => import('@/components/WorkoutExerciseSliderCard'),
  },
  props: {
    activeExerciseId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    activeExerciseId(newValue) {
      const { workoutExercisesByGroup } = this;
      const index = workoutExercisesByGroup.findIndex((group) => {
        const { exercises } = group;
        return exercises.findIndex((exercise) => exercise.exerciseId === newValue) > -1;
      });
      if (index > -1) this.activeIndex = index;
    },
    workoutIsFinished(newValue, oldValue) {
      if (newValue && !oldValue) this.backToOverview();
    },
  },
  computed: {
    ...mapState('workout', ['workoutSession']),
    ...mapGetters('workout', ['workoutExercisesByGroup', 'workoutIsStarted', 'workoutIsFinished']),
    isFirstCard() {
      return this.activeIndex === 0;
    },
    isLastCard() {
      return this.activeIndex === this.workoutExercisesByGroup.length - 1;
    },
  },
  methods: {
    handleSwipe(action) {
      return () => {
        if (action === 'next' && !this.isLastCard) {
          this.nextCard();
        } else if (action === 'prev' && !this.isFirstCard) {
          this.prevCard();
        }
      };
    },
    prevCard() {
      if (this.activeIndex > 0) {
        this.transitionName = 'card-slide-right';
        this.$store.dispatch('video/setActiveVideoId', null);
        this.activeIndex -= 1;
      }
    },
    nextCard() {
      if (this.activeIndex < this.workoutExercisesByGroup.length) {
        this.transitionName = 'card-slide-left';
        this.$store.dispatch('video/setActiveVideoId', null);
        this.activeIndex += 1;
      }
    },
    backToOverview() {
      this.$store.dispatch('video/setActiveVideoId', null);
      this.$emit('close');
    },
  },
  data() {
    return {
      transitionName: '',
      activeIndex: 0,
    };
  },
};
</script>
